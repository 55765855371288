<template>
  <v-dialog v-model="dialog" :width="details ? 1200 : 500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
    </template>
    <v-card class="pt-2">
      <v-img class="mx-2" height="133px" src="/driver_background.jpeg">
        <v-row no-gutters class="justify-center">
          <v-avatar class="mt-4" size="48px">
            <v-img v-if="driver.image" :src="driver.image"/>
            <v-img v-else src="/user_avatar.png"/>
          </v-avatar>
        </v-row>
        <v-row no-gutters class="justify-center mt-2">
          <span class="white--text">{{ shift.driver_name }}</span>
        </v-row>
        <v-row class="justify-center mt-2">
          <span class="text-body-2 white--text">{{ driver.phone | normalizePhone }}</span>
        </v-row>
      </v-img>
      <v-row class="ma-0">
        <v-card flat :class="col">
          <v-row no-gutters dense class="mt-1">
            <v-col cols="11" class="row no-gutters justify-center">
              <span class="text-caption ml-6">Отчет</span>
            </v-col>
            <v-col cols="1" class="row no-gutters justify-center">
              <v-icon class="mb-1" v-if="details" @click="details = !details">mdi-eye</v-icon>
              <v-icon class="mb-1" v-else @click="fetchSingle">mdi-eye-off</v-icon>
            </v-col>
          </v-row>
          <v-card-text class="row no-gutters pt-0 px-0">
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pt-2">Транспорт :</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pt-2">{{ shift.vehicle_name }}</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pb-2">Смена :</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pb-2">{{ shift.hours_count }} ч.</span>
            </v-col>
            <v-col cols="7" class="row no-gutters mt-2 grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pt-2">Гар. оплата за час : </span>
            </v-col>
            <v-col cols="5" class="row no-gutters mt-2 grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pt-2">{{ shift.hours_payment / 100 }} с.</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pb-2">Гар. оплата :</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pb-2">{{ (shift.hours_count * shift.hours_payment) / 100 }} с.</span>
            </v-col>
            <v-col cols="6" class="row no-gutters mt-2 grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pt-2">Бонусы :</span>
            </v-col>
            <v-col cols="6" class="row no-gutters mt-2 grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pt-2">{{ shift.orders_count - rule.bonus_min_count }}</span>
            </v-col>
            <v-col cols="7" class="row no-gutters grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pb-2">Оплата за бонусы :</span>
            </v-col>
            <v-col cols="5" class="row no-gutters grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pb-2">{{ ((shift.orders_count - rule.bonus_min_count) * shift.bonus_payment) / 100 }} с.</span>
            </v-col>
            <v-col v-if="rule.fuel" cols="6" class="row no-gutters mt-2 grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pt-2">Бензин за час :</span>
            </v-col>
            <v-col v-if="rule.fuel" cols="6" class="row no-gutters mt-2 grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pt-2">{{ rule.fuel }} с.</span>
            </v-col>
            <v-col v-if="rule.fuel" cols="6" class="row no-gutters grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pb-2">Оплата за бензин :</span>
            </v-col>
            <v-col v-if="rule.fuel" cols="6" class="row no-gutters grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pb-2">{{ rule.hours_count * rule.fuel }} с.</span>
            </v-col>
            <v-col v-if="shift.fine" cols="6" class="row no-gutters mt-2 grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pt-2">Штрафы :</span>
            </v-col>
            <v-col v-if="shift.fine" cols="6" class="row no-gutters mt-2 grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pt-2">{{ (shift.fine) / 100 }} с.</span>
            </v-col>
            <v-col v-if="shift.fine_comment.String.length > 0" cols="4" class="row no-gutters grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pb-2">Комментарий :</span>
            </v-col>
            <v-col v-if="shift.fine_comment.String.length > 0" cols="8" class="row no-gutters grey lighten-4 justify-end">
              <span class="font-weight-bold font-italic mr-4 pb-2">{{ shift.fine_comment.String }}</span>
            </v-col>
            <v-col cols="6" class="row no-gutters mt-2 grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pt-2">Без. нал. оплата :</span>
            </v-col>
            <v-col cols="6" class="row no-gutters mt-2 grey lighten-4 justify-end">
              <span v-if="shift.cashless_store" class="font-weight-bold mr-4 pt-2">{{ shift.cashless_store }} с.</span>
              <span v-else class="font-weight-bold mr-4 pt-2">-</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pb-2">Аванс :</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pb-2">{{ (shift.prepaid) / 100 }} с.</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-start mt-2">
              <span class="font-weight-bold ml-4 pt-2">З. П. :</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-end mt-2">
              <span class="font-weight-bold mr-4 pt-2">{{ ((shift.hours_count * shift.hours_payment) + ((shift.orders_count - rule.bonus_min_count) * shift.bonus_payment) - shift.fine) / 100 }} с.</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-start">
              <span class="font-weight-bold ml-4 pb-2">К выплате :</span>
            </v-col>
            <v-col cols="6" class="row no-gutters grey lighten-4 justify-end">
              <span class="font-weight-bold mr-4 pb-2">{{ ((shift.hours_count * shift.hours_payment) + ((shift.orders_count - rule.bonus_min_count) * shift.bonus_payment) - shift.fine - shift.prepaid) / 100 }} с.</span>
            </v-col>
          </v-card-text>
        </v-card>
        <v-card v-show="details" flat :class="col2">
          <v-row no-gutters dense class="mt-2 hidden-xs-only">
            <v-col cols="2" class="row no-gutters justify-center">
              <span class="caption">Номер</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-center">
              <span class="caption">Без. нал.</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-center">
              <span class="caption mr-2">Всего</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-center">
              <span class="caption mr-2">Кафе</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-center">
              <span class="caption">Доставка</span>
            </v-col>
            <v-col cols="1" class="row no-gutters justify-center">
              <span class="caption">Бонус</span>
            </v-col>
            <v-col cols="1" class="row no-gutters justify-center">
              <span class="caption">б/н оп.</span>
            </v-col>
          </v-row>
          <v-card-text :class="$vuetify.breakpoint.xs ? '' : 'grey lighten-4'" class="py-0 px-0 mt-1">
            <v-row :class="$vuetify.breakpoint.xs ? 'grey lighten-4 mb-2' : 'mb-1'" no-gutters v-for="(order, index) in driver.orders" :key="index">
              <v-col cols="4" class="row no-gutters hidden-sm-and-up">
                <span class="pt-2 pl-2 font-weight-bold">Номер:</span>
              </v-col>
              <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
                <span class="font-weight-bold mr-2 pt-2">№</span>
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold pt-2">{{ order.number }}</span>
              </v-col>
              <v-col cols="4" class="row no-gutters hidden-sm-and-up">
                <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="pt-2 font-weight-bold">Без. нал.:</span>
              </v-col>
              <v-col cols="8" v-if="order.paybox_id.Valid" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold pt-2">{{ order.paybox_id.Int64 }}</span>
              </v-col>
              <v-col cols="8" v-else class="col col-lg-2 col-md-2 col-sm-2 row no-gutters grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold pt-2">-</span>
              </v-col>
              <v-col cols="4" class="row no-gutters hidden-sm-and-up">
                <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="pt-2 font-weight-bold">Всего:</span>
              </v-col>
              <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold pt-2">{{ ((order.total_price - order.discount) / 100).toFixed(1) }} с.</span>
              </v-col>
              <v-col cols="4" class="row no-gutters hidden-sm-and-up">
                <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="pt-2 font-weight-bold">Кафе:</span>
              </v-col>
              <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold pt-2">{{ ((order.store_price - order.discount) / 100.0).toFixed(1) }} с.</span>
              </v-col>
              <v-col cols="4" class="row no-gutters hidden-sm-and-up">
                <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="pt-2 font-weight-bold">Доставка:</span>
              </v-col>
              <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold pt-2">{{ (order.delivery_price / 100.0).toFixed(1) }} с.</span>
              </v-col>
              <v-col cols="4" class="row no-gutters hidden-sm-and-up">
                <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="pt-2 font-weight-bold">Бонус:</span>
              </v-col>
              <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" v-if="(index + 1) > rule.bonus_min_count" class="font-weight-bold pt-2">{{ shift.bonus_payment / 100 }} с.</span>
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" v-else class="font-weight-bold pt-2">-</span>
              </v-col>
              <v-col cols="4" class="row no-gutters hidden-sm-and-up">
                <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="pt-2 font-weight-bold">Без. нал.:</span>
              </v-col>
              <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" v-if="order.paybox_id.Valid" class="font-weight-bold pt-2">{{ order.store_price / 100 }} с.</span>
                <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" v-else class="font-weight-bold pt-2">-</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="row no-gutters py-0 px-0 mt-3 grey lighten-3">
            <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-center">
              <span class="font-weight-bold py-1">Итого</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-center hidden-xs-only">
              <span class="font-weight-bold py-1"></span>
            </v-col>
            <v-col cols="4" class="row no-gutters hidden-sm-and-up">
              <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="py-1 font-weight-bold">Всего:</span>
            </v-col>
            <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
              <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold py-1">{{ (sumTotalPrice / 100).toFixed(1) }} с.</span>
            </v-col>
            <v-col cols="4" class="row no-gutters hidden-sm-and-up">
              <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="py-1 font-weight-bold">Кафе:</span>
            </v-col>
            <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
              <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold py-1">{{ (sumStorePrice / 100).toFixed(1) }} с.</span>
            </v-col>
            <v-col cols="4" class="row no-gutters hidden-sm-and-up">
              <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="py-1 font-weight-bold">Доставка:</span>
            </v-col>
            <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
              <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold py-1">{{ (sumDeliveryPrice / 100).toFixed(1) }} с.</span>
            </v-col>
            <v-col cols="4" class="row no-gutters hidden-sm-and-up">
              <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="py-1 font-weight-bold">Бонус:</span>
            </v-col>
            <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
              <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold py-1">{{ ((shift.orders_count - rule.bonus_min_count) * shift.bonus_payment) / 100 }} с.</span>
            </v-col>
            <v-col cols="4" class="row no-gutters hidden-sm-and-up">
              <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="py-1 font-weight-bold">Без. нал.:</span>
            </v-col>
            <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
              <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold py-1">{{ shift.cashless_store }} с.</span>
            </v-col>
          </v-card-text>
        </v-card>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

import axios from "@/services/axios";

export default {
  props: {
    shift: Object,
    rule: Object
  },
  data() {
    return {
      driver: {},
      details: false,
      dialog: false
    }
  },

  computed: {
    col() {
      if (!this.details) {
        return "col-12";
      } else if (this.$vuetify.breakpoint.smAndUp) {
        return "col-4";
      } else {
        return "col-12";
      }
    },
    col2() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return "col-12";
      } else if (this.details) {
        return "col-8";
      } else {
        return "col-0";
      }
    },
    sumTotalPrice() {
      let total = 0;
      if (this.driver && this.driver.orders) {
        for (let i = 0; i < this.driver.orders.length; i++) {
          total = total + this.driver.orders[i].total_price - this.driver.orders[i].discount;
        }
      }
      return total;
    },
    sumStorePrice() {
      let total = 0;
      if (this.driver && this.driver.orders) {
        for (let i = 0; i < this.driver.orders.length; i++) {
          total = total + this.driver.orders[i].store_price - this.driver.orders[i].discount;
        }
      }
      return total;
    },
    sumDeliveryPrice() {
      let total = 0;
      if (this.driver && this.driver.orders) {
        for (let i = 0; i < this.driver.orders.length; i++) {
          total = total + this.driver.orders[i].delivery_price;
        }
      }
      return total;
    }
  },

  methods: {
    fetchSingle() {
      let date = (this.shift.closed_at).toString().substr(0, 10);
      axios.send({
        url: "/stat/drivers/" + this.shift.driver_id + "?date=" + date,
        method: "GET",
      }).then((resp) => {
        this.driver = resp.data;
        this.details = !this.details;
      })
    }
  },

  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return "";
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "");
        let temp = "+";
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " ";
          }
          temp = temp + phone[i];
        }
        result = result + temp + ",";
      })
      return result.substr(0, result.length - 1);
    }
  }
}
</script>
