<template>
  <div class="my-5">
    <v-row no-gutters class="mx-1 my-2">
      <v-col cols="8" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters align-center d-flex">
        <VueCtkDateTimePicker
            id="date"
            v-model="dateRange"
            :range="temp"
            :firstDayOfWeek="week"
            :onlyDate="onlyDate"
            button-color="#3FC44F"
            autoClose
            class="my-2"
            color="#3FC44F"
            format="MM-DD-YYYY"
            :formatted="formatted"
            label="Выберите промежуток времени"
            locale="ru"/>
      </v-col>
      <v-col cols="4" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters align-center d-flex">
        <v-btn :small="$vuetify.breakpoint.xsOnly" @click="fetchClosed" color="primary" class="ml-2">Показать</v-btn>
      </v-col>
    </v-row>
    <v-card v-if="closedList.length" class="my-5 pb-1">
      <v-row dense class="mt-4 mx-2 hidden-xs-only">
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">ФИО</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">Транспорт</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">Закрыт</span>
        </v-col>
        <v-col cols="2" class="row no-gutters">
          <v-col cols="4" class="row no-gutters justify-center">
            <span class="text-caption">Смена</span>
          </v-col>
          <v-col cols="4" class="row no-gutters justify-center">
            <span class="text-caption">Заказы</span>
          </v-col>
          <v-col cols="4" class="row no-gutters justify-end">
            <span class="text-caption text-no-wrap">К выплате</span>
          </v-col>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">Без. нал.</span>
        </v-col>
        <v-col cols="3" class="justify-center align-center d-flex">
          <span class="text-caption"></span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">Детально</span>
        </v-col>
      </v-row>
      <div v-for="shift in closedList" :key="shift.id">
        <closed-driver :shift="shift" :date="date"/>
      </div>
    </v-card>
  </div>
</template>

<script>

import closedDriver from "./list.vue";
import {format} from "date-fns";

export default {
  components: {closedDriver},
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    dialog: false,
    dateRange: {},
    temp: true,
    week: 1,
    onlyDate: true,
    formatted: 'll',
  }),

  computed: {
    closedList() {
      return this.$store.getters["calculation/closedList"]
    }
  },

  methods: {
    fetchClosed() {
      let payload = {};

      if (this.dateRange && this.dateRange.start && this.dateRange.end) {
        payload.start = format(new Date(this.dateRange.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.dateRange.end), "yyyy-MM-dd")
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      this.$store.dispatch("calculation/fetchClosedRange", queryString);
    },
  },
}
</script>
