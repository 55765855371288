<template>
  <v-row no-gutters :class="rowColor" class="mx-1 py-1 mb-1">
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="caption">ФИО:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
      <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="text-body-2">{{ shift.driver_name }}</span>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="caption">Транспорт:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
      <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="text-body-2">{{ shift.vehicle_name }}</span>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="caption">Закрыт:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
      <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="text-body-2">{{ shift.closed_at | timeFormat }}</span>
    </v-col>
    <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters d-flex align-center">
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="caption">Смена:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
        <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="text-body-2">{{ shift.hours_count }} ч.</span>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="caption">Заказы:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
        <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="text-body-2">{{ shift.orders_count }}</span>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="caption">К выплате:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class=" col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
        <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="text-body-2">{{ (((shift.hours_payment * shift.hours_count) + (shift.bonus_count * shift.bonus_payment)) - shift.fine - shift.prepaid) / 100 }} с.</span>
      </v-col>
    </v-col>
    <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'pl-2' : ''" class="caption">Без. нал.:</span>
    </v-col>
    <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
      <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" v-if="shift.cashless_store" class="text-body-2">{{ shift.cashless_store }} с.</span>
      <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" v-else class="text-body-2">-</span>
    </v-col>
    <v-col cols="3" :class="$vuetify.breakpoint.xs ? 'pt-2' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
      <v-btn v-if="!shift.payed_at" color="primary" x-small @click="paymentCash">
        <span class="caption text-lowercase">Оплата</span>
      </v-btn>
      <v-btn v-else disabled x-small>
        <span class="caption text-lowercase">Расчет</span>
      </v-btn>
    </v-col>
    <v-col cols="3" :class="$vuetify.breakpoint.xs ? 'pt-2' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
      <v-btn v-if="!shift.cashless_payed_at" dark color="indigo" x-small @click="paymentCashless">
        <span class="caption text-lowercase">Без. нал.</span>
      </v-btn>
      <v-btn v-else disabled x-small>
        <span class="caption text-lowercase">Расчет</span>
      </v-btn>
    </v-col>
    <v-col cols="3" :class="$vuetify.breakpoint.xs ? 'pt-2' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
      <v-btn @click="remove" dark color="error" x-small>
        <span class="caption text-lowercase">Удалить</span>
      </v-btn>
    </v-col>
    <v-col cols="3" :class="$vuetify.breakpoint.xs ? 'pt-2' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
      <info v-if="shift && rule" :shift="shift" :rule="rule"/>
    </v-col>
  </v-row>
</template>

<script>

import info from './details.vue';
import format from "date-fns/format";

export default {
  components: { info },
  props: {
    shift: Object,
  },

  mounted() {
    this.$store.dispatch("calculation/fetchRules");
  },

  computed: {
    rules() {
      return this.$store.getters["calculation/rules"];
    },
    rule() {
      return this.rules.find(r => r.vehicle_type_id === this.shift.vehicle_type_id && r.hours_count === this.shift.hours_count);
    },
    rowColor() {
      if(this.shift.payed_at && this.shift.cashless_payed_at) {
        return 'primary lighten-4'
      }
      if (this.shift.payed_at || this.shift.cashless_payed_at) {
        return 'blue lighten-4'
      }
      return 'grey lighten-4'
    }
  },
  methods: {
    paymentCash() {
      this.$confirm("Рассчитать?",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("calculation/cash", this.shift.id)
        this.dialog = false;
      })
    },
    paymentCashless() {
      this.$confirm("Рассчитать без. нал.?",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("calculation/cashless", this.shift.id)
        this.dialog = false;
      })
    },
    remove() {
      this.$confirm("Удалить запись?",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("calculation/remove", this.shift.id)
        this.dialog = false;
      })
    }
  },
  filters: {
    timeFormat(d) {
      return format(new Date(d), "yyyy-MM-dd");
    },
  }
}
</script>
